// BlogApi.js

const dummyData = [
  {
    title:
      "Exploring Jodhpur: The Ultimate taxi Service in jodhpur for Your Adventure",
    description:
      "Jodhpur, famously known as the Blue City for its stunning azure-painted buildings, is a treasure trove of history, culture, and breathtaking landscapes. Whether you’re visiting the magnificent Mehrangarh Fort, strolling through the vibrant bazaars, or indulging in local cuisine, the best way to experience all that Jodhpur has to offer is by taxi service jodhpur. Here’s why opting for a rental car is the perfect choice for your Jodhpur adventure",
    image: require("../../blog-container/blog-lists/ExploringJodhpurRoadTrip/mehraangadh.jpg"),
    navigateTo: "/exploring-Jodhpur-road-trip-taxi-in-jodhpur",
  },
  {
    title:
      "Discover the Best Car Tours in Jodhpur: A Blend of Culture, Nature, and Convenience",
    description:
      "Jodhpur, the Blue City of India, is a popular travel destination known for its rich heritage, grand forts, and stunning landscapes. To fully immerse yourself in its splendor, exploring the city via car tours in Jodhpur is the perfect choice. Whether you're looking to explore the historical sites or the natural beauty surrounding the city, having a reliable car hire service can make your journey more enjoyable. In this blog, we will explore the best cab services in Jodhpur, how to choose the right taxi services in Jodhpur, and what makes car rental in Jodhpur a must-have for travelers.",
    image: require("../../blog-container/blog-lists/DiscovertheBestCarToursinJodhpur/Jodhpur.png"),
    navigateTo: "/discover-the-Best-Car-Tours-in-Jodhpur",
  },
  {
    title: "Car Tours Jodhpur",
    description:
      "Jodhpur, the Blue City of India, is a popular travel destination known for its rich heritage, grand forts, and stunning landscapes. To fully immerse yourself in its splendor, exploring the city via car tours in Jodhpur is the perfect choice. Whether you're looking to explore the historical sites or the natural beauty surrounding the city, having a reliable car hire service can make your journey more enjoyable. In this blog, we will explore the best cab services in Jodhpur, how to choose the right taxi services in Jodhpur, and what makes car rental in Jodhpur a must-have for travelers.",
    image:
      "https://lh7-rt.googleusercontent.com/docsz/AD_4nXejsY8svqzDFERndHC9QvqM639LcsSodaeigXoYDCITW3qW7tCh7OLP8suucaUKFqu8WJrS_z5MONVSe05NR7BZyZt--FHhy-sMlTz6eg1ev9r4E5nvCK4WDiEJ3SPE_vC_8FFZcxUNVkjvNOKMqp1ciL5S5GaZIIGEvKGE8BqwkJETAfKAWx8?key=TWsNh16VyJLb9lo2e5qhig",
    navigateTo: "/car-tours-jodhpur-blogs",
  },
  {
    title: "Discover Rajasthan with Our Reliable Cab Service",
    description:
      " Jodhpur, the Blue City, is a captivating destination  with its rich history, stunning architecture, and vibrant culture. Whether you’re visiting the majestic Mehrangarh Fort, the intricate Umaid Bhawan Palace, or the bustling local markets, navigating through          the city is essential for a seamless travel experience. This is where          taxi services in Jodhpur come to your rescue, offering convenient,          comfortable, and reliable transport options for both locals and          tourists.",
    image: require("../blog-lists/dicoverwithreliablecab/images/airport-jodhpur.png"),
    navigateTo: "/exploring-Jodhpur-with-Reliable-Taxi-Services",
  },
  {
    title: "Exploring Rajasthan by Car: A Royal Road Trip Adventure",
    description:
      "Rajasthan, the land of kings, is a mesmerizing state full of vibrant culture, majestic forts, sprawling deserts, and bustling markets. One of the best ways to explore this diverse region is by embarking on a car tour. Whether you're looking to delve into the history of ancient forts or experience the vibrant local life, Rajasthan’s scenic routes promise unforgettable moments. Here's how to plan the perfect road trip across Rajasthan.",
    image: require("../blog-lists/rajasthanbycar/rajasthan-best-tour-taravel.png"),
    navigateTo: "/exploring-rajasthan-by-car-A-Royal-Road-Trip-Adventure",
  },
  {
    title:"Hidden Gems of Rajasthan You Can Explore with a Car rental jodhpur",
    description:"While the popular tourist spots like Jaipur, Udaipur, and Jodhpur are well-known, Rajasthan is also home to lesser-known but equally enchanting destinations. Car rental jodhpur gives you the flexibility to explore these hidden gems.",
    image: require("../../blog-container/blog-lists/hiddengem/bundi.jpg"),
    navigateTo:"/Hidden-Gems-of-Rajasthan-You-Can-Explore-with-a-Car-rental-jodhpur"
  },
  {
    title:"Exploring Rajasthan Through a Cab service in jodhpur: A Journey to Remember ",
    description:"Rajasthan, the Land of Kings, is a vibrant and colorful state in India that boasts of majestic forts, palaces, deserts, and rich cultural heritage. While there are many ways to explore this beautiful state, traveling through Rajasthan in a cab service in jodhpur offers unmatched freedom and flexibility. From the grand cities to the offbeat paths,cab service in jodhpur journey allows you to soak in the essence of Rajasthan at your own pace. Here's how to make the most of your cab journey through Rajasthan",
    image: require("../../blog-container/blog-lists/gerneyToRemember/umaid-bhawan.jpg"),
    navigateTo:"/Rajasthan-Through-a-Cab-service-in-jodhpur-A-Journey-to-Remember"
  },
  {
    title:"Exploring Jodhpur in Comfort with Innova Crysta by car tours Jodhpur",
    description:"When you think of Jodhpur, the first things that come to mind are the majestic forts, palaces, vibrant markets, and the beautiful blue hues of the old city. Known as the Blue City or the Jodhpur has plenty to offer its visitors. Whether you're visiting for leisure or business, comfort and convenience are paramount to making your experience enjoyable. This is where renting an Innova Crysta by car tours Jodhpur can elevate your trip",
    image: require("../../blog-container/blog-lists/inovacrysta/inova.jpg"),
    navigateTo:"/exploring-jodhpur-in-comfort-with-innova-crysta-by-car-tours-jodhpur"
  },
  
];

export const fetchBlogData = async () => {
  return dummyData;
};
