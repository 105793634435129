import React from "react";
import { useLocation } from "react-router-dom";

const cars = [
  {
    id: 1,
    name: "Toyota Innova",
    image: "https://static3.toyotabharat.com/images/showroom/innova-hycross/hy-concept-img.webp", // Real image link
    description: "A comfortable 7-seater car.",
  },
  {
    id: 2,
    name: "Honda City",
    image: "https://www.hondacarindia.com/_next/image?url=https%3A%2F%2Fwww.hondacarindia.com%2Fweb-data%2Fmodels%2F2023%2Fcity5thGen%2FExperience%2FColors%2B%2FColors%2FDesktop%2FAr_Vk_Honda-City_3TX_Front-3-4th-Studio-Shot_Lunar-Silver-Metallic_V2.png&w=1920&q=75", // Real image link
    description: "A stylish and fuel-efficient sedan.",
  },
  {
    id: 3,
    name: "Swift Dzire",
    image: "https://www.marutisuzuki.com/images/dzire_brand/images/threesixty/blue/suzuki_swift_ext_360_oragne_v-1_0.webp", // Real image link
    description: "A compact sedan with great mileage.",
  },
];

export default function TotalCars() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get("from");
  const to = queryParams.get("to");
  const date = queryParams.get("date");

  return (
    <div className="p-6">
      <h1 className="text-4xl font-bold text-center mb-6">
        Cars from {from} to {to} on {date || "any date"}
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {cars.map((car) => (
          <div
            key={car.id}
            className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center"
          >
            <img
              src={car.image}
              alt={car.name}
              className="w-full h-48 object-cover mb-4 rounded-lg"
            />
            <h2 className="text-2xl font-bold mb-2">{car.name}</h2>
            <p className="text-gray-700">{car.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
